// import moment from 'moment';
// import config from '~/config';
import { date as commonDateTransform } from '~/network/mainClient/common/transforms/responses';
import utils from '~/utils';

export const getAllSharesItem = (data) => {
    const price = parseFloat(data.price);
    const minimumInvestmentAmount = parseFloat(data.estate.minimum_investment_amount);
    const estate = {
        id: data.estate.id,
        image: data.estate.image,
        title: data.estate.name,
        type: data.estate.type.name,
        url: data.estate.url,
    };
    const result = {
        id: data.id || -1,
        estate,
        price,
        priceFormatted: utils.formats.formatNumberWithSpaces(price),
        userShares: data.user_shares,
        userSharesAvailable: data.user_shares_available,
        minimumInvestmentAmount,
        minimumInvestmentAmountFormatted: utils.formats.formatNumberWithSpaces(minimumInvestmentAmount),
        maxTradePrice: data.max_trade_price || null,
        maxTradePriceFormatted: data.max_trade_price ? utils.formats.formatNumberWithSpaces(data.max_trade_price, true) : null,
        minTradePrice: data.min_trade_price || null,
        minTradePriceFormatted: data.min_trade_price ? utils.formats.formatNumberWithSpaces(data.min_trade_price, true) : null,
        totalIssuedQuantity: data.quantity,
        commissionForBuyer: data.buyer_commission,
        commissionForSeller: data.seller_commission,
    };
    return result;
};

export const getAllShares = (data) => {
    const result = {
        items: data.results.map(getAllSharesItem),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
    };
    return result;
};

export const getActiveSharesItem = (data) => {
    const price = parseFloat(data.price);
    const estate = {
        address: data.estate.address,
        description: data.estate.description,
        id: data.estate.id,
        image: data.estate.image,
        title: data.estate.name,
        targetProfitability: data.estate.target_profitability,
        type: data.estate.type.name,
        url: data.estate.url,
    };
    const result = {
        id: data.id || -1,
        estate,
        price,
        priceFormatted: utils.formats.formatNumberWithSpaces(price),
        userShares: data.user_shares,
        commissionForBuyer: data.buyer_commission,
        commissionForSeller: data.seller_commission,
    };
    return result;
};

export const getActiveShares = (data) => {
    const result = {
        items: data.results.map(getActiveSharesItem),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
    };
    return result;
};

export const ordersListItem = (data) => {
    const price = parseFloat(data.price);
    const sharePrice = parseFloat(data.share.price);
    const sum = price * data.quantity;
    const statuses = {
        'active': 'Ожидает отклика',
        'wait_sign': 'Ожидает подтверждения',
        'signed': 'Подтверждена',
        'completed': 'Выполнена',
        'canceled': 'Отменена',
    };
    const types = {
        'sale': 'Продажа',
        'buy': 'Покупка',
    };
    return {
        estate: {
            id: data.share.estate.id,
            url: data.share.estate.url,
            title: data.share.estate.name,
            image: data.share.estate.image,
        },
        share: {
            id: data.share.id,
            price: sharePrice,
            priceFormatted: utils.formats.formatNumberWithSpaces(sharePrice) + ' руб.',
            commissionForBuyer: data.share.buyer_commission,
            commissionForSeller: data.share.seller_commission,
        },
        amount: data.quantity,
        amountFormatted: utils.formats.formatNumberWithSpaces(data.quantity),
        date: data.created,
        dateFormatted: commonDateTransform(data.created),
        id: data.id,
        price,
        priceFormatted: utils.formats.formatNumberWithSpaces(price) + ' руб.',
        status: data.status,
        statusTitle: statuses[data.status],
        sum,
        sumFormatted: utils.formats.formatNumberWithSpaces(sum) + ' руб.',
        type: data.direction,
        typeTitle: types[data.direction],
        isOwner: data.is_owner,
        isPackage: !data.is_partial,
        // isStatusFormRequired: !!data.is_status_form_required,
        isStatusFormRequired: false,
        commission: data.commission,
        commissionFormatted: utils.formats.formatNumberWithSpaces(data.commission) + ' руб.',
        sumWithCommission: sum + data.commission,
        sumWithCommissionFormatted: utils.formats.formatNumberWithSpaces(sum + data.commission) + ' руб.',
    };
};

export const ordersList = (data) => {
    const result = {
        items: data.results.map(ordersListItem),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
    };
    return result;
};

export const dealsListItem = (data) => {
    const statuses = {
        'active': 'В процессе',
        'processing': 'В обработке',
        'completed': 'Завершена',
        'canceled': 'Отменена',
    };
    const types = {
        'sale': 'Продажа',
        'buy': 'Покупка',
    };
    const direction = data.buyer_order.is_owner ? 'buy' : 'sale';
    const price = parseFloat(data.price);
    const sum = price * data.quantity;
    const result = {
        id: data.id,
        status: data.status,
        statusTitle: statuses[data.status],
        date: commonDateTransform(data.created),
        type: direction,
        typeTitle: types[direction],
        price,
        priceFormatted: utils.formats.formatNumberWithSpaces(price) + ' руб.',
        amount: data.quantity,
        sum,
        sumFormatted: utils.formats.formatNumberWithSpaces(sum) + ' руб.',
        buyerDocument: data.buyer_document,
        buyerOrder: data.buyer_order ? ordersListItem(data.buyer_order) : null,
        sellerDocument: data.seller_document,
        sellerOrder: data.seller_order ? ordersListItem(data.seller_order) : null,
        get ownerOrder() {
            return direction === 'sale' ? this.sellerOrder : this.buyerOrder;
        },
        get ownerDocument() {
            return direction === 'sale' ? this.sellerDocument : this.buyerDocument;
        },
        get document() {
            return direction === 'sale' ? (this.sellerDocument || this.buyerDocument) : this.buyerDocument || this.sellerDocument;
        },
        get counterOrder() {
            return direction === 'sale' ? this.buyerOrder : this.sellerOrder;
        },
        estate: {
            id: data.share.estate.id,
            url: data.share.estate.url,
            title: data.share.estate.name,
            image: data.share.estate.image,
        },
        share: {
            id: data.share.id,
            price: parseFloat(data.share.price),
        },
    };
    if (result.ownerOrder.commission) {
        result.commission = result.ownerOrder.commission;
        result.commissionFormatted = utils.formats.formatNumberWithSpaces(result.ownerOrder.commission) + ' руб.';
        result.sumWithCommission = sum + result.ownerOrder.commission;
        result.sumWithCommissionFormatted = utils.formats.formatNumberWithSpaces(result.sumWithCommission) + ' руб.';
    }
    return result;
};

export const dealsList = (data) => {
    const result = {
        items: data.results.map(dealsListItem),
        paging: {
            count: data.count || 0,
            page: data.page || 1,
        },
    };
    return result;
};

// eslint-disable-next-line camelcase
export const subscriptionsItem = ({ is_subscribed_to_sale, is_subscribed_to_buy, estate, ...data }) => {
    const result = {
        ...data,
        id: estate,
        isSubscribedToSale: is_subscribed_to_sale,
        isSubscribedToBuy: is_subscribed_to_buy,
    };
    return result;
};

export const subscriptions = ({ count, page, results }) => {
    const result = {
        items: results.map(subscriptionsItem),
        paging: {
            count: count || 0,
            page: page || 1,
        },
    };
    return result;
};
